import React, { memo } from 'react';
import styled from '@emotion/styled';
import { switchProp } from 'styled-tools';
import { breakpoints } from '~/theme/breakpoints';
import isPropValid from '@emotion/is-prop-valid';

export type TNotification = {
    text: string;
    type: 'primary' | 'secondary';
};
export const NotificationWrap = styled('div', {
    shouldForwardProp: (prop) => isPropValid(prop) && prop !== 'multiple',
})<{
    multiple?: boolean;
    isCampaignRedirectAvailable?: boolean;
}>(({ isCampaignRedirectAvailable, multiple }) => ({
    display: 'flex',
    width: '100%',
    justifyContent: isCampaignRedirectAvailable ? 'center' : multiple ? 'space-between' : 'center',
    position: 'absolute',
    top: 7,
    [breakpoints.sm]: {
        top: -2,
    },
}));

export const Label = styled.span<Pick<TNotification, 'type'>>(
    ({ theme }) => ({
        ...theme.mixins.useTextStyle('productInfo'),
        display: 'block',
    }),
    switchProp(
        'type',
        {
            secondary: ({ theme }) => ({
                color: theme.colors.black,
            }),
            primary: ({ theme }) => ({
                color: theme.colors.onSale,
            }),
        },
        'secondary',
    ),
);

export const Notifications = memo(
    ({
        notifications,
        isCampaignRedirectAvailable,
    }: {
        notifications: TNotification[];
        isCampaignRedirectAvailable?: boolean;
    }) => {
        return (
            <NotificationWrap
                multiple={notifications.length > 1}
                isCampaignRedirectAvailable={isCampaignRedirectAvailable}
            >
                {notifications.map(({ type, text }) => (
                    <Label key={text} type={type}>
                        {text}
                    </Label>
                ))}
            </NotificationWrap>
        );
    },
);

Notifications.displayName = 'Notifications';
